import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { getSingleBlogPageId } from '../../util/urlHelpers';

export const SINGLE_BLOG_PAGE_ID = 'SingleBlogPage';
export const loadData = (params, search) => dispatch => {
  const pageId = getSingleBlogPageId(params.pageId);
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent));
};
