// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import axios from 'axios';
import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

export const getCurrentUser = body => {
  return post('/api/get-user-info', body);
};

export const getTransactions = body => {
  return post('/api/get-transactions', body);
};

export const sendFinancialHardshipForm = body => {
  return post('/api/financial-hardship', body);
};

export const getSinglePageAsset = body => {
  return post('/api/get-singlepage-asset', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const updateCustomerProfile = body => {
  return post('/api/update-user', body);
};

// export const createXpayPaymentIntent = body => {
//   return post('/api/xpay/create-payment-intent', body);
// };

// export const anzCreateTokenizedSession = () => {
//   return post('/api/anz/tokenized-session', {});
// };

// export const anzCreatePayment = body => {
//   return post('/api/anz/create-payment', body);
// };

// export const anzCapturePayment = body => {
//   return post('/api/anz/capture-payment', body);
// };

// export const anzGetPayment = body => {
//   return post('/api/anz/get-payment', body);
// };

// export const anzSubsequentPayment = body => {
//   return post('/api/anz/subsequent-payment', body);
// };

// export const anzRefundPayment = body => {
//   return post('/api/anz/refund-payment', body);
// };

export const accessToken = async () => {
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/Get-access-token',
      {
        refressToken: '3a2298a6d8e5f77609ffc88b809d4df1ddcbc80204d8e0bb0590f371ebb51733',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return intialResponse?.data;
  } catch (e) {
    throw { e };
  }
};

export const createXpayPaymentIntent = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = axios.post(
      'https://tib-backend.shopdev.co/Create-payment-intent',
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse.then(response => {
      return response?.data;
    });
  } catch (e) {
    throw { e };
  }
};

export const createXpayPaymentRefund = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/Create-payment-refund',
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data;
  } catch (e) {
    throw { e };
  }
};

export const anzCreateTokenizedSession = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/tokenized_session',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data?.data;
  } catch (e) {
    throw { e };
  }
};

export const anzCreatePayment = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/create_payment',
      { payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data?.data;
  } catch (e) {
    throw { e };
  }
};

export const anzSubsequentPayment = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/subsequent_payment',
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data?.data;
  } catch (e) {
    throw { e };
  }
};

export const anzRefundPayment = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/refund_payment',
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data;
  } catch (e) {
    throw { e };
  }
};

export const anzGetPayment = async payload => {
  const token = await accessToken();
  try {
    const intialResponse = await axios.post(
      'https://tib-backend.shopdev.co/get_payment',
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token?.data?.accessToken}`,
        },
      }
    );
    return intialResponse?.data;
  } catch (e) {
    throw { e };
  }
};

export const currenyExchangeRatesForUSD = async () => {
  try {
    const intialResponse = await axios.get(
      'https://v6.exchangerate-api.com/v6/b7620938b49a26fcac099ed1/latest/USD'
    );
    return intialResponse?.data;
  } catch (e) {
    return false;
  }
};
