import React, { useState } from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import {
  LINE_ITEM_NIGHT,
  DATE_TYPE_DATE,
  LINE_ITEM_HOUR,
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import { subtractTime } from '../../util/dates';

import css from './OrderBreakdown.module.css';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import IconClose from '../IconClose/IconClose';
import { formatMoney } from '../../util/currency';
const { types } = require('sharetribe-flex-sdk');

const BookingPeriod = props => {
  const {
    startDate,
    endDate,
    dateType,
    timeZone,
    removeSlot,
    listIndex,
    isPrebookedSelected,
  } = props;
  const timeZoneMaybe = timeZone
    ? { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
    : null;
  const timeFormatOptions =
    dateType === DATE_TYPE_DATE || isPrebookedSelected
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const canRemove =
    window &&
    window.sessionStorage &&
    window.location.href.includes('/therapist/') &&
    !window.location.href.includes('/checkout');

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSectionLeft}>
          {/* <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div> */}
          <div className={css.dayInfo}>
            <FormattedDate
              value={startDate}
              {...timeFormatOptions}
              {...dateFormatOptions}
              {...timeZoneMaybe}
            />
          </div>
          {/* <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div> */}
        </div>

        <div className={css.bookingPeriodSectionRight}>
          {/* <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingEnd" />
          </div> */}

          <div className={css.dayInfo}>
            {!isPrebookedSelected && (
              <FormattedDate
                value={endDate}
                {...timeFormatOptions}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            )}

            {canRemove && (
              <button
                className={css.removeSlot}
                type="button"
                onClick={() => removeSlot(listIndex)}
              >
                <IconClose rootClassName={css.closeIcon} size="small" />
              </button>
            )}
          </div>
          {/* <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div> */}
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const {
    booking,
    dateType,
    timeZone,
    selectedTimeSlots,
    // lineItems,
    intl,
    // isProvider,
    userRole,
    marketplaceCurrency,
    // isCustomer,
    marketplaceName,
    transaction,
    updatedBookings,
    removeSlot,
    isFree,
    coupDataOnConfirmation,
    isViewDetailOrder = false,
    couponValue,
    handleCouponSubmit,
    isPrebookedSelected,
  } = props;
  const { Money } = types;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems;
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );

  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;
  const hasCommissionLineItem = lineItems?.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  // const classes = classNames(rootClassName || css.root, className);
  if (!booking) {
    return null;
  }

  const totalQuantity = updatedBookings?.pricing?.totalQuantity || lineItems?.[0]?.quantity?.d?.[0];
  const totalAmount =
    updatedBookings?.pricing?.totalAmount ||
    transaction?.attributes?.protectedData?.couponDataForOrderConfirmationPage?.actualTotalAmount;

  const isCommission = lineItem => {
    return (
      lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
      lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  };

  const nonCommissionReversalLineItems = lineItems => {
    return lineItems.filter(item => !isCommission(item) && item.reversal);
  };

  const refundLineItems = nonCommissionReversalLineItems(lineItems);

  const hasRefund = refundLineItems.length > 0;
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings

  return (
    <>
      {!isPrebookedSelected ? (
        <div className={css.bookingPeriod}>
          <div className={css.bookingPeriodSectionLeft}>
            <div className={css.dayLabel}>
              <FormattedMessage id="OrderBreakdown.bookingStart" />
            </div>
          </div>
          <div className={css.bookingPeriodSectionRight}>
            <div className={css.dayLabel}>
              <FormattedMessage id="OrderBreakdown.bookingEnd" />
            </div>
          </div>
        </div>
      ) : (
        <div className={css.bookingPeriod}>
          <div className={css.bookingPeriodSectionLeft}>
            <div className={css.dayLabel}>
              <span>Sessions Booking</span>
            </div>
          </div>
        </div>
      )}

      {updatedBookings?.bookings?.map((data, index) => {
        const { start, end, displayStart, displayEnd } = data.attributes;
        const localStartDate = displayStart || start;
        const localEndDateRaw = displayEnd || end;
        const isNightly = lineItemUnitType === LINE_ITEM_NIGHT;
        const isHour = lineItemUnitType === LINE_ITEM_HOUR;
        const endDay =
          isNightly || isHour ? localEndDateRaw : subtractTime(localEndDateRaw, 1, 'days');
        return (
          <>
            <div className={`${css.lineItem} ${css.lineItem_slot}`}>
              <BookingPeriod
                key={index}
                startDate={localStartDate}
                endDate={endDay}
                dateType={dateType}
                timeZone={timeZone}
                removeSlot={removeSlot}
                listIndex={index}
                isPrebookedSelected={isPrebookedSelected}
              />
            </div>
          </>
        );
      })}
      {/* <hr className={css.totalDivider} /> */}
      <LineItemBasePriceMaybe
        lineItems={
          updatedBookings?.bookings?.[0]?.[0] ? [updatedBookings?.bookings?.[0]?.[0]] : lineItems
        }
        code={lineItemUnitType}
        intl={intl}
        totalQty={updatedBookings?.pricing?.totalQuantity}
        pricing={updatedBookings?.pricing}
        isFree={isFree}
        isViewDetailOrder={isViewDetailOrder}
        coupDataOnConfirmation={coupDataOnConfirmation}
        hasRefund={hasRefund}
      />
      {!isFree && (
        <>
          <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
          <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
          <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
          <LineItemSubTotalMaybe
            transaction={transaction}
            lineItems={
              updatedBookings?.bookings?.[0]?.[0]
                ? [updatedBookings?.bookings?.[0]?.[0]]
                : lineItems
            }
            code={lineItemUnitType}
            userRole={userRole}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
            totalAmount={totalAmount}
            coupDataOnConfirmation={coupDataOnConfirmation}
            hasRefund={hasRefund}
          />

          <LineItemCustomerCommissionMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            marketplaceName={marketplaceName}
            intl={intl}
          />
          {/* <LineItemCustomerCommissionRefundMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            marketplaceName={marketplaceName}
            intl={intl}
          /> */}
          <LineItemProviderCommissionMaybe
            lineItems={lineItems}
            isProvider={isProvider}
            marketplaceName={marketplaceName}
            intl={intl}
            hasRefund={hasRefund}
          />
          {/* <LineItemProviderCommissionRefundMaybe
            lineItems={lineItems}
            isProvider={isProvider}
            marketplaceName={marketplaceName}
            intl={intl}
          /> */}
        </>
      )}

      {coupDataOnConfirmation?.couponApplied && coupDataOnConfirmation?.couponValueSingle ? (
        <>
          <hr className={css.totalDivider} />
          <div className={css.coupon_div}>
            <div className={css.totalLabel}>
              {<FormattedMessage id="OrderBreakdown.couponValue" />}
              <span className={css.remove_btn} onClick={handleCouponSubmit}>
                {coupDataOnConfirmation?.showRemoveBtn ? 'Remove' : null}
              </span>
            </div>
            <div className={css.totalPrice}>{`-${formatMoney(
              intl,
              new Money(
                (coupDataOnConfirmation?.couponValueSingle || 0) * totalQuantity,
                marketplaceCurrency
              )
            )}`}</div>
          </div>
        </>
      ) : null}

      <LineItemRefundMaybe
        lineItems={lineItems}
        intl={intl}
        marketplaceCurrency={marketplaceCurrency}
      />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        selectedTimeSlots={selectedTimeSlots} // Pass the selectedTimeSlots prop
        updatedBookings={updatedBookings}
        isFree={isFree}
        isViewDetailOrder={isViewDetailOrder}
        coupDataOnConfirmation={coupDataOnConfirmation}
        hasRefund={hasRefund}
      />
      {!hasRefund && !isFree && hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </>
  );
};
LineItemBookingPeriod.defaultProps = { booking: {}, dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
